import React from "react";
import Image from "next/image";

export default function MiniLogo({ size }) {
  return (
    <>
    <Image
        alt=""
        src="/images/logo2-wh.png"
        layout="fixed"
        width="330"
        height="89"
        priority={true}
      />
    </>
  )
}