import React, { useState, useEffect } from "react";
import Head from "next/head";
import CmsRow from "./CmsRow";
import CmsFooter from "./CmsFooter";

import dynamic from "next/dynamic";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsPage({ structure }) {
  const CmsMap = dynamic(() => import("./CmsMap"), {
    ssr: false,
  });

  useEffect(() => {
    if (document.readyState !== "loading") {
      /* HOMEPAGE */
      applyAosToElement(".homepage #riga1 .checkContainer", "fade-left");
      applyAosToElementByIndex(
        ".homepage #riga2 #card-group-1657726991 > div",
        0,
        "fade-right"
      );
      applyAosToElementByIndex(
        ".homepage #riga2 #card-group-1657726991 > div",
        1,
        "fade-up"
      );
      applyAosToElementByIndex(
        ".homepage #riga2 #card-group-1657726991 > div",
        2,
        "fade-left"
      );
      applyAosToElement(".homepage #riga3", "fade-up");
      applyAosToElement(".homepage #riga4 .text", "fade-up");
      applyAosToElement(".homepage #map-title .text", "fade-up");
      applyAosToElement(".homepage #map-title .text", "fade-up");
      applyAosToElement(".homepage .mapOverlayBox", "fade-right");
      Aos.init();
    }
  }, []);

  const iubendaImplementation = `
  <script type="text/javascript">
    var _iub = _iub || [];
    _iub.csConfiguration = {"ccpaAcknowledgeOnDisplay":true,"ccpaAcknowledgeOnLoad":true,"ccpaApplies":true,"consentOnContinuedBrowsing":false,"countryDetection":true,"enableCcpa":true,"floatingPreferencesButtonDisplay":"bottom-right","gdprAppliesGlobally":false,"invalidateConsentWithoutLog":true,"lang":"en","perPurposeConsent":true,"purposes":"1","siteId":2780572,"whitelabel":false,"cookiePolicyId":99548895, "banner":{ "acceptButtonColor":"#15A1B1","acceptButtonDisplay":true,"backgroundColor":"#3A3A3C","closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonColor":"#15A1B1","rejectButtonDisplay":true,"usesThirdParties":false }};
    </script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></script>
    <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`;

  return (
    <>
      <Head>
        <title>{structure.page.title}</title>
        <meta name="description" content={structure.page.description} />
      </Head>
      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
      <main
        className={`${structure.page.template} cmsPage`}
        id={`${structure.page.slug ?? ""}`}
      >
        {structure.rows.map(function (row, i) {
          if (row.id == "map-title") {
            return (
              <div key={i}>
                <CmsRow row={row} page_id={structure.page.id} />
                <CmsMap row={row} />
              </div>
            );
          } else {
            return <CmsRow row={row} page_id={structure.page.id} key={i} />;
          }
        })}
      </main>
      <CmsFooter structure={structure} />
    </>
  );
}

function applyAosToElement(selector, effect) {
  if (document.querySelector(selector)) {
    document.querySelector(selector).setAttribute("data-aos", effect);
  }
}

function applyAosToElementByIndex(selector, index, effect) {
  if (document.querySelectorAll(selector)[index]) {
    document.querySelectorAll(selector)[index].setAttribute("data-aos", effect);
  }
}

export default CmsPage;
