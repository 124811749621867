import dynamic from "next/dynamic";
import CmsRow from "./CmsRow";

import MiniLogo from "./MiniLogo"

function CmsFooter({ structure }) {
  return (
    <footer id="footer">
      <div className="container mx-auto">
        <div className="logo">
          <MiniLogo />
        </div>
      </div>
      {structure.footer.map(function (row, i) {
        return (
          <CmsRow row={row} key={i} />
        )
      })}
    </footer>
  );
}

export default CmsFooter;
