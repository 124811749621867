import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { NavLink } from "./NavLink";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Router from "next/router";

function CmsMenuDropdown({ link, mobile=false }) {
  let [isOverButton, setIsOverButton] = useState(false);
  let [isOverList, setIsOverList] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [isTouchInput, setIsTouchInput] = useState();
  let [hasClicked, setHasClicked] = useState();

  const { asPath } = useRouter();
  let button = useRef(null);

  useEffect(() => {

    if(!mobile) {
      if (isOpen && !isOverButton && !isOverList && !isTouchInput) {
        // button.current.click();
        setIsOpen(false);
      } else if (!isOpen && (isOverButton || isOverList) && !isTouchInput) {
        // button.current.click();
        setIsOpen(true);
      }
    }

    setIsTouchInput(false);
    setHasClicked(false);

  }, [hasClicked, isOverButton, isOverList, isOpen, isTouchInput, mobile]);

  return (
    <div className="relative">
      <Link href={link.path != "" ? link.path : "#"}>
        <a
          onTouchStart={(event) => {
            // console.log('onTouchStart', isOpen);
            // event.preventDefault();
            setIsTouchInput(true);
          }}
          onMouseEnter={(event) => {
            // console.log('onMouseEnter', isOpen);
            event.preventDefault();
            if(!mobile) {
              setIsOverButton(true);
            }
          }}
          onMouseLeave={(event) => {
            // console.log('onMouseLeave', isOpen);
            event.preventDefault();
            if(!mobile) {
              setIsOverButton(false);
            }
          }}
          onClick={(event) => {
            // console.log('onClick', isOpen);
            event.preventDefault();
            event.stopPropagation();
            if(mobile) {
              // console.log('isOpen', isOpen);
              setIsOpen(!isOpen);
              if (link.path != "") {
                Router.push(link.path);
              }
            } else {
              if(!isTouchInput) {
                if (link.path != "" && isOverButton) {
                  Router.push(link.path);
                } else {
                  setHasClicked(true);
                  setIsOpen(!isOpen);
                }
              } else {
                setIsOpen(!isOpen);
              }
            }
          }}
          onKeyDown={(event) => {
            // console.log('onKeyDown', isOpen);
            event.preventDefault();
            if(!mobile) {
              setIsOpen(!isOpen);
            }
          }}
          ref={button}
          className={`${isOpen ? "isOpen" : ""} ${link.active ? "active" : ""}`}
        >
          {link.name}
          {!isOpen ? (
            <ChevronDownIcon className="dropdown-icon-down" />
          ) : (
            <ChevronUpIcon className="dropdown-icon-up" />
          )}
        </a>
      </Link>

      <div className="dropdown-panel">
        <div
          className={`dropdown-panel-inner columns-${link.columns} ${
            isOpen ? "block" : "hidden"
          }`}
          onMouseEnter={(event) => {
            setIsOverList(true);
          }}
          onMouseLeave={(event) => {
            setIsOverList(false);
          }}
        >
          {link.navigation.map(function (link, i) {
            return <NavLink link={link} key={i} exact />;
          })}
        </div>
      </div>
    </div>
  );
}

export default CmsMenuDropdown;
