import React from "react";
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { sendMail } from "../lib/api-lib";

function CmsForm() {
  const [showModal, setShowModal] = React.useState(true);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      subject: "Message from website",
      message: event.target.message.value,
    };

    setShowLoading(true);

    // const response = await sendMail(data);

    fetch(process.env.BASE_URL + "/api/sendmail", {
      body: JSON.stringify(data),
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setShowLoading(false);
        if (data.response == 1) {
          setShowResult(1);
        } else {
          setShowResult(2);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        setShowResult(2);
      });
  };

  const closeForm = () => {
    document.querySelector("body, html").classList.remove("no-scroll");
    setShowModal(false);
    setShowResult(0);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="contact-form">
            <div className="contact-form-container relative">
              {showResult == 0 ? (
                <>
                  {showLoading ? (
                    <div className="absolute w-full h-full theme-b5 z-10 flex flex-col justify-center items-center">
                      <TailSpin
                        type="Puff"
                        color="#15A1b1"
                        height={70}
                        width={70}
                        timeout={3000}
                      />
                      <div>sending...</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*content*/}
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="form-contact">
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <div className="form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_name"
                          >
                            Full name <span className="required">*</span>
                          </label>
                          <input
                            id="form_name"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Full name (required)"
                            name="name"
                            required
                          />
                        </div>
                        <div className="form-row grid grid-cols-2 gap-4">
                          <div className="form-fld">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Phone
                            </label>
                            <input
                              id="form_phone"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Phone"
                              name="phone"
                            />
                          </div>
                          <div className="form-fld">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_email"
                            >
                              Email <span className="required">*</span>
                            </label>
                            <input
                              id="form_email"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Email (obbligatorio)"
                              name="email"
                              required
                            />
                          </div>
                        </div>
                        {/* <div className="form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_subject"
                          >
                            Oggetto
                          </label>
                          <input
                            id="form_subject"
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Oggetto"
                            name="subject"
                          />
                        </div> */}
                        <div className="form-row">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_message"
                          >
                            Message
                          </label>
                          <textarea
                            id="form_message"
                            className="appearance-none bg-transparent border-none w-full h-32 text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Messaggio"
                            name="message"
                          ></textarea>
                        </div>
                      </div>
                      {/*footer*/}
                      <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="externalBtn" type="submit">
                          SEND MESSAGE
                        </button>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="form-cms-container-inner">
                    <div className="relative p-6 flex-auto">
                      <div className="z-10 flex flex-col justify-center items-center">
                        {showResult == 1 ? (
                          <>
                            <h5 className="theme-c2 text-center">
                              Thanks for contacting us!
                            </h5>
                            <p className="font-normal text-center">
                              We will respond to your request as soon as
                              possible
                            </p>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </>
                        ) : (
                          <>
                            <h5 className="text-red-600 text-center">
                              An error has occurred!
                            </h5>
                            <p className="font-normal mt-8 text-center">
                              We apologize for any inconvenience.
                              <br />
                              Please contact us by writing to{" "}
                              <a
                                href="mailto:tarponville@yahoo.com"
                                className="theme-c2"
                              >
                                tarponville@yahoo.com
                              </a>
                            </p>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default CmsForm;
